























import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Visibility from 'visibilityjs';

const Profile = namespace('Profile');

@Component
export default class ProfileConnector extends Vue {
  public profileLoading: boolean = false;

  @Profile.State
  public balance!: number;

  @Profile.State
  public bank!: string;

  @Profile.State
  public refCode!: string;

  @Profile.State
  public accountNumber!: string;

  @Profile.State
  public accountName!: string;

  @Profile.State
  public username!: string;

  @Profile.State
  public affiliateRevenue!: number;

  @Profile.State
  public agentApiUsername!: string;

  @Profile.State
  public refundLoss!: string;

  @Profile.State
  public point!: number;

  @Profile.State
  public fortuneWheel!: number;

  @Profile.State
  public recentGames!: Array<any>;

  @Profile.Action
  // eslint-disable-next-line @typescript-eslint/ban-types
  public fetchBalance!: () => {}

  @Profile.Action
  // eslint-disable-next-line @typescript-eslint/ban-types
  public getProfile!: () => {}

  @Profile.Action
  public findUnreadGiveAway!: () => { data: any }

  @Profile.Action
  // eslint-disable-next-line @typescript-eslint/ban-types
  public markreadGiveaway!: (id: string) => {}

  public async checkBalance() {
    this.profileLoading = true;
    await this.fetchBalance();
    await this.getProfile();
    this.profileLoading = false;
    this.$store.commit('Snackbar/showNotification', { message: 'ดึงข้อมูลสำเร็จ', error: false });
  }

  private async fetchBonus() {
    const unread = await this.findUnreadGiveAway();
    console.log('created', unread.data);
    if (unread.data) {
      // eslint-disable-next-line no-underscore-dangle
      await this.markreadGiveaway(unread.data._id);
      this.$store.dispatch('Dialog/updateDialog', { open: true, dialogType: 'GiveawayDialog', dialogData: unread.data }, { root: true });
    }
  }

  async created() {
    await this.fetchBalance();
    await this.getProfile();
    await this.fetchBonus();
    Visibility.change(async () => {
      if (Visibility.state() === 'visible') {
        await this.fetchBonus();
      }
    });
  }
}
