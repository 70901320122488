import { AppService } from './AbstractService';

export default class AffiliateService extends AppService {
  public async getMyAffiliate() {
    return this.makeRequest('GET', '/users/me/affiliates');
  }

  public async withdrawAffiliate() {
    return this.makeRequest('POST', '/transactions/affiliate-withdraw');
  }

  public async getShare(period: string) {
    return this.makeRequest('GET', `/shares/me?period=${period || ''}`);
  }

  public async cliamShare(shareId: string) {
    return this.makeRequest('PUT', `/shares/${shareId}`);
  }
}
