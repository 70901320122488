



































































































































































































































































































import {
  Component, Vue, Watch, Emit,
} from 'vue-property-decorator';
import BaseDataTable from '@/components/BaseDataTable.vue';
import ProfileConnector from '@/connector/Profile.vue';
import AuthConnector from '@/connector/Auth.vue';
import moment from 'moment';
// eslint-disable-next-line import/no-cycle
import AffiliateService from '@/services/AffiliateService';

const affiliateService = new AffiliateService();

@Component({
  components: {
    BaseDataTable,
    ProfileConnector,
    AuthConnector,
  },
})
export default class AffiliatePage extends Vue {
  public share: any = {};

  public linkDialog: boolean = false;

  public loading: boolean = false;

  public claimLoading: boolean = false;

  public date: string = moment().format('YYYY-MM');

  // eslint-disable-next-line class-methods-use-this
  generateRefUrl(refCode: string) {
    return `${window.location.host}/?ref=${refCode}&register=true`;
  }

  public async copyAfLink(link: string) {
    navigator.clipboard.writeText(link);
    this.$store.commit('Snackbar/showNotification', { message: 'คัดลอกลิงก์แล้ว', error: false });
  }

  // eslint-disable-next-line class-methods-use-this
  shareUrl() {
    // eslint-disable-next-line no-underscore-dangle
    if (this.share._id) {
      // eslint-disable-next-line no-underscore-dangle
      return `${process.env.VUE_APP_API_ENDPOINT}/shares/detail/me`;
    }
    return '';
  }

  get isClaimable() {
    if (this.share) {
      const current = moment().valueOf();
      const shareCaculateEnd = moment(this.share.period).endOf('month').valueOf();
      if (current < shareCaculateEnd) {
        return false;
      }
      if (this.share.is_paid) {
        return false;
      }
      return true;
    }
    return true;
  }

  // eslint-disable-next-line class-methods-use-this
  get monthsOption() {
    const currentMonth = moment();
    const maximumData = moment().subtract(6, 'month');
    const options: any = [];
    while (currentMonth > maximumData) {
      options.push(currentMonth.format('YYYY-MM'));
      currentMonth.subtract(1, 'month');
    }
    return options;
  }

  async created() {
    await this.getShare();
  }

  @Watch('date')
  async onDateChange() {
    await this.getShare();
  }

  async getShare() {
    const { data } = await affiliateService.getShare(this.date);
    this.share = { ...data };
  }

  async claimShare() {
    try {
      this.claimLoading = true;
      // eslint-disable-next-line no-underscore-dangle
      const { data } = await affiliateService.cliamShare(this.share._id);
      this.share = { ...data };
      this.$store.commit('Snackbar/showNotification', { message: 'ถอนเงินสำเร็จ', error: false });
    } catch (e) {
      this.$store.commit('Snackbar/showNotification', { message: e.message, error: true });
    }
    this.claimLoading = false;
  }
}
